import { createEpic } from '../helpers';
import {
  getGameDetailAsync,
  getGameDetailSandboxAsync,
  getGameStatsAsync,
} from './dashboardActions';

export const getGameDetailEpic = createEpic(
  getGameDetailAsync,
  'dashboard',
  'getGameDetail',
  true
);

export const getGameStatsEpic = createEpic(
  getGameStatsAsync,
  'dashboard',
  'getGameStatus',
  true
);

export const getGameDetailSandboxEpic = createEpic(
  getGameDetailSandboxAsync,
  'dashboard',
  'getGameDetailSandbox',
  true
);
