import { createAsyncAction } from '../helpers';

export const getReportAsync = createAsyncAction('GET_REPORT');
export const getStoreReportAsync = createAsyncAction('GET_STORE_REPORT');
export const getReportStatusAsync = createAsyncAction('GET_REPORT_STATUS');
export const getStoreReportStatusAsync = createAsyncAction(
  'GET_STORE_REPORT_STATUS'
);
export const exportReportAsync = createAsyncAction('EXPORT_REPORT');
export const exportStoreReportAsync = createAsyncAction('EXPORT_STORE_REPORT');
