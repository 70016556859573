import { compose, combineReducers, createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { persistStore } from 'redux-persist';
import { createLogger } from 'redux-logger';

import services from '../services';

import { authReducer } from './auth/authReducer';
import * as authEpics from './auth/authEpics';

import { gamesReducer } from './games/gamesReducer';
import * as gamesEpics from './games/gamesEpics';

import { dashboardReducer } from './dashboard/dashboardReducer';
import * as dashboardEpics from './dashboard/dashboardEpics';

import { storeReducer } from './stores/storesReducer';
import * as storesEpic from './stores/storesEpic';

import { storeDraftReducer } from './stores-draft/storesDraftReducer';
import * as storesDraftEpic from './stores-draft/storesDraftEpic';

import { downloadReducer } from './download/downloadReducer';
import * as downloadEpic from './download/downloadEpic';

import { downloadDraftReducer } from './downloadDraft/downloadDraftReducer';
import * as downloadDraftEpic from './downloadDraft/downloadDraftEpic';

import { versionsReducer } from './versions/versionsReducer';
import * as versionsEpic from './versions/versionsEpics';

import { itemReducer } from './item/itemReducer';
import * as itemEpic from './item/itemEpics';

import { reportsReducer } from './reports/reportsReducer';
import * as reportsEpic from './reports/reportsEpic';

import { downloadHistoryReducer } from './download-history/downloadHistoryReducer';
import * as downloadHistoryEpic from './download-history/downloadHistoryEpic';

import { itemSubmissionReducer } from './item-submission/itemSubmissionReducer';
import * as itemSubmissionEpic from './item-submission/itemSubmissionEpic';

import { reviewReducer } from './reviews/reviewsReducer';
import * as reviewsEpic from './reviews/reviewsEpic';

let composeEnhancers;
if (process.env.NODE_ENV === 'production') {
  composeEnhancers = compose;
} else {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const epicMiddleware = createEpicMiddleware({
  dependencies: services,
});

const rootReducer = combineReducers({
  auth: authReducer,
  games: gamesReducer,
  dashboard: dashboardReducer,
  stores: storeReducer,
  storesDraft: storeDraftReducer,
  downloads: downloadReducer,
  downloadDraft: downloadDraftReducer,
  versions: versionsReducer,
  item: itemReducer,
  reports: reportsReducer,
  downloadHistory: downloadHistoryReducer,
  itemSubmission: itemSubmissionReducer,
  reviews: reviewReducer,
});

const rootEpic = combineEpics(
  ...Object.values(authEpics),
  ...Object.values(gamesEpics),
  ...Object.values(dashboardEpics),
  ...Object.values(storesEpic),
  ...Object.values(storesDraftEpic),
  ...Object.values(downloadEpic),
  ...Object.values(downloadDraftEpic),
  ...Object.values(versionsEpic),
  ...Object.values(itemEpic),
  ...Object.values(reportsEpic),
  ...Object.values(downloadHistoryEpic),
  ...Object.values(itemSubmissionEpic),
  ...Object.values(reviewsEpic)
);

const logger = createLogger({
  collapsed: true,
});

export const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'production'
    ? composeEnhancers(applyMiddleware(epicMiddleware))
    : composeEnhancers(applyMiddleware(logger, epicMiddleware))
);
epicMiddleware.run(rootEpic);
export const persistor = persistStore(store);

//epicMiddleware.run(rootEpic);
