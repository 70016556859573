import { handleActions } from 'redux-actions';
import {
  getLatestStoreDraftAsync,
  submitStoreDraftAsync,
  uploadCoverDraftAsync,
  uploadCsvDraftAsync,
} from './storesDraftAction';

const initialState = {
  latestStoreDraft: {},
  submitActionDraft: {
    coverDraftSuccess: false,
    coverDraftLoading: false,
    coverDraftError: '',
    csvDraftSuccess: false,
    csvDraftLoading: false,
    csvDraftError: '',
  },
  fetchDraft: {
    loadingDraft: false,
    errorDraft: '',
  },
  actionDraft: {
    loadingDraft: false,
    successDraft: false,
    changedDraft: null,
    uploadedDraft: false,
    errorDraft: '',
    submitErrorDraft: '',
  },
};
export const storeDraftReducer = handleActions(
  new Map([
    // Get latest Store
    [
      getLatestStoreDraftAsync.request,
      (state) => ({
        ...state,
        fetchDraft: {
          ...state.fetchDraft,
          loadingDraft: true,
        },
      }),
    ],
    [
      getLatestStoreDraftAsync.success,
      (state, { payload }) => ({
        ...state,
        latestStoreDraft: payload,
        fetchDraft: {
          ...state.fetchDraft,
          loadingDraft: false,
        },
      }),
    ],
    [
      getLatestStoreDraftAsync.failure,
      (state, { payload }) => ({
        ...state,
        latestStoreDraft: initialState.latestStoreDraft,
        fetchDraft: {
          ...initialState.fetchDraft,
          loadingDraft: false,
          errorDraft: payload,
        },
      }),
    ],

    // submit store draft
    [
      submitStoreDraftAsync.request,
      (state) => ({
        ...state,
        actionDraft: {
          ...state.actionDraft,
          loadingDraft: true,
        },
      }),
    ],
    [
      submitStoreDraftAsync.success,
      (state, { payload }) => ({
        ...state,
        actionDraft: {
          ...state.actionDraft,
          successDraft: payload,
          loadingDraft: false,
        },
      }),
    ],
    [
      submitStoreDraftAsync.failure,
      (state, { payload }) => ({
        ...state,
        actionDraft: {
          ...state.actionDraft,
          loadingDraft: false,
          submitErrorDraft: payload,
        },
      }),
    ],

    // Upload csv draft
    [
      uploadCsvDraftAsync.request,
      (state) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          csvDraftSuccess: false,
          csvDraftLoading: true,
          csvDraftError: '',
        },
      }),
    ],
    [
      uploadCsvDraftAsync.success,
      (state, { payload }) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          csvDraftSuccess: payload,
          csvDraftLoading: false,
          csvDraftError: false,
        },
      }),
    ],
    [
      uploadCsvDraftAsync.failure,
      (state, { payload }) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          csvDraftSuccess: false,
          csvDraftLoading: false,
          csvDraftError: payload,
        },
        actionDraft: {
          ...state.actionDraft,
          successDraft: false,
        },
      }),
    ],

    // Upload cover draft
    [
      uploadCoverDraftAsync.request,
      (state) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          coverDraftSuccess: false,
          coverLoading: true,
          coverDraftError: '',
        },
      }),
    ],
    [
      uploadCoverDraftAsync.success,
      (state, { payload }) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          coverDraftSuccess: payload,
          coverLoading: false,
          coverDraftError: '',
        },
      }),
    ],
    [
      uploadCoverDraftAsync.failure,
      (state, { payload }) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          coverDraftSuccess: false,
          coverLoading: false,
          coverDraftError: payload,
        },
        actionDraft: {
          ...state.actionDraft,
          successDraft: false,
        },
      }),
    ],
  ]),
  initialState
);
