import { createEpic, createUploadEpic } from '../helpers';
import {
  deleteStoreDraftAsync,
  getLatestStoreDraftAsync,
  submitStoreDraftAsync,
  uploadCoverDraftAsync,
  uploadCsvDraftAsync,
} from './storesDraftAction';

export const submitStoreDraftEpic = createEpic(
  submitStoreDraftAsync,
  'storesDraft',
  'submitStoreDraft',
  true
);
export const uploadCoverDraftEpic = createUploadEpic(
  uploadCoverDraftAsync,
  'storesDraft',
  'uploadCoverDraft',
  true
);
export const uploadCsvDraftEpic = createUploadEpic(
  uploadCsvDraftAsync,
  'storesDraft',
  'uploadCsvDraft',
  true
);
export const getLatestStoreDraftEpic = createEpic(
  getLatestStoreDraftAsync,
  'storesDraft',
  'getLatestStoreDraft',
  true
);
export const deleteStoreDraftEpic = createEpic(
  deleteStoreDraftAsync,
  'storesDraft',
  'deleteStoreDraft',
  true
);
