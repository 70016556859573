import { createEpic, createUploadEpic } from '../helpers';
import {
  getDownloadDraftAsync,
  submitDownloadDraftAsync,
  uploadMediaDraftAsync,
  uploadLogoDraftAsync,
} from './downloadDraftAction';

export const getDownloadDraftEpic = createEpic(
  getDownloadDraftAsync,
  'downloadDraft',
  'getDownloadDraft',
  true
);

export const submitDownloadDraftEpic = createEpic(
  submitDownloadDraftAsync,
  'downloadDraft',
  'submitDownloadDraft',
  true
);

export const uploadMediaDraftEpic = createUploadEpic(
  uploadMediaDraftAsync,
  'downloadDraft',
  'uploadMediaDraft',
  true
);
export const uploadLogoEpic = createUploadEpic(
  uploadLogoDraftAsync,
  'downloadDraft',
  'uploadLogoDraft',
  true
);
