import { api, download } from '../helpers';

export const getReport = (param, token) =>
  api(`/developer/game/report`, 'GET', undefined, param, token);
export const getStoreReport = ({ id, ...param }, token) =>
  api(`/developer/game/${id}/report`, 'GET', undefined, param, token);
export const downloadStoreReport = ({ id }, token) =>
  download(`/developer/game/${id}/report/export`, undefined, token);
export const downloadReport = (param, token) =>
  download(`/developer/game/report/export`, undefined, token);
export const getStoreStatus = ({ id, ...param }, token) =>
  api(`/developer/game/${id}/report/status`, 'GET', undefined, param, token);
export const getStatus = (param, token) =>
  api(`/developer/game/report/status`, 'GET', undefined, param, token);
