import { combineActions, handleActions } from 'redux-actions';
import {
  getAllVersionAsync,
  getLastDownloadAsync,
  getDownloadCategoriesAsync,
  submitDownloadAsync,
  uploadMediaAsync,
  uploadLogoAsync,
  submitPublishDownloadAsync,
} from './downloadAction';

const initialState = {
  versionList: [],
  categories: [],
  downloadDetail: {},
  fetch: {
    versionLoading: false,
    categoriesLoading: false,
    downloadDetailLoading: false,
  },
  mediaAction: {
    loading: false,
    success: false,
    error: '',
  },
  logoAction: {
    loading: false,
    success: false,
    error: '',
  },
  action: {
    loading: false,
    success: false,
    submitError: '',
    error: '',
  },
};
export const downloadReducer = handleActions(
  new Map([
    // Get categories
    [
      getDownloadCategoriesAsync.request,
      (state) => ({
        ...state,
        fetch: {
          ...state.fetch,
          categoriesLoading: true,
        },
      }),
    ],
    [
      getDownloadCategoriesAsync.success,
      (state, { payload }) => ({
        ...state,
        categories: payload,
        fetch: {
          ...state.fetch,
          categoriesLoading: false,
        },
      }),
    ],
    [
      getDownloadCategoriesAsync.failure,
      (state, { payload }) => ({
        ...state,
        categories: initialState.categories,
        fetch: initialState.fetch,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],

    // Get all Versions
    [
      getAllVersionAsync.request,
      (state) => ({
        ...state,
        fetch: {
          ...state.fetch,
          versionLoading: true,
        },
      }),
    ],
    [
      getAllVersionAsync.success,
      (state, { payload }) => ({
        ...state,
        versionList: payload,
        fetch: {
          ...state.fetch,
          versionLoading: false,
        },
      }),
    ],
    [
      getAllVersionAsync.failure,
      (state, { payload }) => ({
        ...state,
        versionList: initialState.versionList,
        fetch: initialState.fetch,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],

    // Get last download
    [
      getLastDownloadAsync.request,
      (state) => ({
        ...state,
        fetch: {
          ...state.fetch,
          downloadDetailLoading: true,
        },
      }),
    ],
    [
      getLastDownloadAsync.success,
      (state, { payload }) => ({
        ...state,
        downloadDetail: payload,
        fetch: {
          ...state.fetch,
          downloadDetailLoading: false,
        },
      }),
    ],
    [
      getLastDownloadAsync.failure,
      (state, { payload }) => ({
        ...state,
        downloadDetail: initialState.downloadDetail,
        fetch: initialState.fetch,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],

    // Submit Download
    [
      combineActions(
        submitDownloadAsync.request,
        submitPublishDownloadAsync.request
      ),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(
        submitDownloadAsync.success,
        submitPublishDownloadAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      combineActions(
        submitDownloadAsync.failure,
        submitPublishDownloadAsync.failure
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          submitError: payload,
        },
      }),
    ],

    // Submit Media
    [
      combineActions(uploadMediaAsync.request),
      (state) => ({
        ...state,
        mediaAction: {
          ...initialState.mediaAction,
          loading: true,
        },
      }),
    ],
    [
      combineActions(uploadMediaAsync.success),
      (state, { payload }) => ({
        ...state,
        mediaAction: {
          ...initialState.mediaAction,
          success: payload,
        },
      }),
    ],
    [
      combineActions(uploadMediaAsync.failure),
      (state, { payload }) => ({
        ...state,
        mediaAction: {
          ...initialState.mediaAction,
          submitError: payload,
        },
      }),
    ],

    // Submit Logo
    [
      combineActions(uploadLogoAsync.request),
      (state) => ({
        ...state,
        logoAction: {
          ...initialState.logoAction,
          loading: true,
        },
      }),
    ],
    [
      combineActions(uploadLogoAsync.success),
      (state, { payload }) => ({
        ...state,
        logoAction: {
          ...initialState.logoAction,
          success: payload,
        },
      }),
    ],
    [
      combineActions(uploadLogoAsync.failure),
      (state, { payload }) => ({
        ...state,
        logoAction: {
          ...initialState.logoAction,
          submitError: payload,
        },
      }),
    ],
  ]),
  initialState
);
