import { createEpic } from '../helpers';
import {
  getItemsAsync,
  getItemDetailAsync,
  submitItemAsync,
  updateItemAsync,
  getItemListAsync,
  deleteItemAsync,
} from './itemActions';

export const getItemsEpic = createEpic(getItemsAsync, 'item', 'getList', true);
export const getItemDetailEpic = createEpic(
  getItemDetailAsync,
  'item',
  'getDetail',
  true
);
export const deleteItemEpic = createEpic(
  deleteItemAsync,
  'item',
  'deleteItem',
  true
);
export const submitItemEpic = createEpic(
  submitItemAsync,
  'item',
  'submitItem',
  true
);
export const updateItemEpic = createEpic(
  updateItemAsync,
  'item',
  'updateItem',
  true
);
export const getItemListEpic = createEpic(
  getItemListAsync,
  'item',
  'getItems',
  true
);
