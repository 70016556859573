import { api, upload } from '../helpers';

export const getList = (params, token) =>
  api('/developer/game/me', 'GET', undefined, params, token);
export const create = (payload, token) =>
  api('/developer/game/create', 'POST', payload, undefined, token);
export const uploadThumbnail = ({ id, thumbnail, progressEvent }, token) => {
  const formData = new FormData();
  formData.append('thumbnail', thumbnail, thumbnail.name);
  return upload(
    `/developer/game/${id}/thumbnail`,
    'PUT',
    formData,
    token,
    progressEvent
  );
};
export const getDetail = ({ id }, token) =>
  api(`/developer/game/${id}/detail`, 'GET', undefined, undefined, token);
export const update = ({ id, ...payload }, token) =>
  api(`/developer/game/${id}`, 'PUT', payload, undefined, token);
export const checkUserIsAdmin = ({ id }, token) =>
  api(`/developer/game/${id}/access/check`, 'GET', undefined, undefined, token);
export const getAccessUserList = ({ id, ...params }, token) =>
  api(`/developer/game/${id}/access/users`, 'GET', undefined, params, token);
export const getAccessUserDetail = ({ id, developerId }, token) =>
  api(
    `/developer/game/${id}/access/users/${developerId}`,
    'GET',
    undefined,
    undefined,
    token
  );
export const updateAccessUser = ({ id, developerId, ...payload }, token) =>
  api(
    `/developer/game/${id}/access/users/${developerId}`,
    'PUT',
    payload,
    undefined,
    token
  );
export const deleteAccessUser = ({ id, developerId }, token) =>
  api(
    `/developer/game/${id}/access/users/${developerId}`,
    'DELETE',
    undefined,
    undefined,
    token
  );
export const submitUserAccess = (payload, token) =>
  api(`/developer/register-developer`, 'POST', payload, undefined, token);
