import { createAsyncAction } from '../helpers';

export const getGamesAsync = createAsyncAction('GET_GAMES');
export const createGameAsync = createAsyncAction('CREATE_GAME');
export const getGameDetailAsync = createAsyncAction('GET_GAME_DETAIL');
export const updateGameAsync = createAsyncAction('UPDATE_GAME');
export const uploadGameThumbnailAsync = createAsyncAction(
  'UPLOAD_GAME_THUMBNAIL'
);
export const getAccessUserListAsync = createAsyncAction('GET_ACCESS_USER_LIST');
export const getAccessUserDetailAsync = createAsyncAction(
  'GET_ACCESS_USER_DETAIL'
);
export const updateAccessUserAsync = createAsyncAction('UPDATE_ACCESS_USER');
export const deleteAccessUserAsync = createAsyncAction('DELETE_ACCESS_USER');
export const submitUserAccessAsync = createAsyncAction('SUBMIT_USER_ACCESS');
