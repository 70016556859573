import { combineActions, handleActions } from 'redux-actions';
import {
  getGameDetailAsync,
  getGameDetailSandboxAsync,
  getGameStatsAsync,
} from './dashboardActions';

const initialState = {
  stats: {
    purchases: -1,
    dayPurcase: -1,
    monthPurchase: -1,
    score: -1,
    views: -1,
    totalReview: -1,
  },
  detail: {},
  action: {
    detailLoading: false,
    statLoading: false,
    success: false,
    detailError: '',
    statError: '',
  },
};

export const dashboardReducer = handleActions(
  new Map([
    [
      getGameStatsAsync.request,
      (state, { payload }) => ({
        ...state,
        action: {
          ...state.action,
          statLoading: true,
          stateError: false,
        },
      }),
    ],
    [
      getGameStatsAsync.success,
      (state, { payload }) => ({
        ...state,
        stats: payload,
        action: {
          ...state.action,
          statLoading: false,
          statError: false,
        },
      }),
    ],
    [
      getGameStatsAsync.failure,
      (state, { payload }) => ({
        ...state,
        stats: initialState.stats,
        action: {
          ...state.action,
          statLoading: false,
          statError: payload,
        },
      }),
    ],

    [
      combineActions(
        getGameDetailAsync.request,
        getGameDetailSandboxAsync.request
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...state.action,
          detailLoading: true,
          detailError: '',
        },
      }),
    ],
    [
      combineActions(
        getGameDetailAsync.success,
        getGameDetailSandboxAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        detail: payload,
        action: {
          ...state.action,
          detailLoading: false,
          detailError: '',
        },
      }),
    ],
    [
      combineActions(
        getGameDetailAsync.failure,
        getGameDetailSandboxAsync.failure
      ),
      (state, { payload }) => ({
        ...state,
        detail: initialState.detail,
        action: {
          ...state.action,
          detailLoading: false,
          detailError: payload,
        },
      }),
    ],
  ]),
  initialState
);
