import { createEpic, createUploadEpic } from '../helpers';
import {
  submitItemSubmissionAsync,
  uploadCsvItemSubmissionAsync,
  getItemSubmissionNotRejectedAsync,
  getLatestItemSubmissionAllStatusAsync,
} from './itemSubmissionAction';

export const submitItemSubmissionEpic = createEpic(
  submitItemSubmissionAsync,
  'itemSubmission',
  'submitItemSubmission',
  true
);
export const uploadCsvItemSubmissionEpic = createUploadEpic(
  uploadCsvItemSubmissionAsync,
  'itemSubmission',
  'uploadCsvItemSubmission',
  true
);
export const getItemSubmissionNotRejectedEpic = createEpic(
  getItemSubmissionNotRejectedAsync,
  'itemSubmission',
  'getItemSubmissionNotRejected',
  true
);
export const getLatestItemSubmissionAllStatusEpic = createEpic(
  getLatestItemSubmissionAllStatusAsync,
  'itemSubmission',
  'getLatestItemSubmissionAllStatus',
  true
);
