import { handleActions, combineActions } from 'redux-actions';
import {
  getItemsAsync,
  getItemDetailAsync,
  submitItemAsync,
  updateItemAsync,
  getItemListAsync,
  deleteItemAsync,
} from './itemActions';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  items: [],
  detail: {
    prices: [],
  },
  fetch: {
    loading: false,
    error: '',
  },
  action: {
    loading: false,
    success: false,
    error: '',
  },
};

export const itemReducer = handleActions(
  new Map([
    // Get list items
    [
      getItemsAsync.request,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getItemsAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
        action: {
          ...initialState.action,
        },
      }),
    ],
    [
      getItemsAsync.failure,
      (state, { payload }) => ({
        ...state,
        list: initialState.list,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],

    // Get items
    [
      getItemListAsync.request,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getItemListAsync.success,
      (state, { payload }) => ({
        ...state,
        items: payload,
        action: {
          ...initialState.action,
        },
      }),
    ],
    [
      getItemListAsync.failure,
      (state, { payload }) => ({
        ...state,
        items: initialState.items,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],

    // Get item detail
    [
      getItemDetailAsync.request,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getItemDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
        action: {
          ...initialState.action,
        },
      }),
    ],
    [
      getItemDetailAsync.failure,
      (state, { payload }) => ({
        ...state,
        detail: initialState.detail,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],

    // Create item
    [
      combineActions(
        submitItemAsync.request,
        updateItemAsync.request,
        deleteItemAsync.request
      ),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(
        submitItemAsync.success,
        updateItemAsync.success,
        deleteItemAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      combineActions(
        submitItemAsync.failure,
        updateItemAsync.failure,
        deleteItemAsync.failure
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
