import { createAsyncAction } from '../helpers';

export const getAllVersionAsync = createAsyncAction('GET_ALL_VERSION');
export const getDownloadCategoriesAsync = createAsyncAction(
  'GET_DOWNLOAD_CATEGORIES'
);
export const getLastDownloadAsync = createAsyncAction('GET_LAST_DOWNLOAD');
export const submitDownloadAsync = createAsyncAction('SUBMIT_DOWNLOAD');
export const uploadMediaAsync = createAsyncAction('SUBMIT_DOWNLOAD_MEDIA');
export const uploadLogoAsync = createAsyncAction('SUBMIT_DOWNLOAD_LOGO');
export const submitPublishDownloadAsync = createAsyncAction(
  'SUBMIT_PUBLISH_DOWNLOAD'
);
