import { createAsyncAction } from '../helpers';

export const getDownloadDraftAsync = createAsyncAction('GET_DOWNLOAD_DRAFT');
export const submitDownloadDraftAsync = createAsyncAction(
  'SUBMIT_DOWNLOAD_DRAFT'
);
export const uploadMediaDraftAsync = createAsyncAction(
  'SUBMIT_DOWNLOAD_MEDIA_DRAFT'
);
export const uploadLogoDraftAsync = createAsyncAction(
  'SUBMIT_DOWNLOAD_LOGO_DRAFT'
);
