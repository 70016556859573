import { createAction } from 'redux-actions';
import { createAsyncAction } from '../helpers';

export const authLoginAsyncAction = createAsyncAction('AUTH_LOGIN');

export const authLogoutAction = createAction('AUTH_LOGOUT');

export const checkActivationKeyAsync = createAsyncAction('AUTH_CHECK_KEY');

export const submitActivationKeyAsync = createAsyncAction('AUTH_CHECK_SUBMIT');

export const requestForgotKeyAsync = createAsyncAction('FORGOT_REQUEST');

export const checkPasswordKeyAsync = createAsyncAction('FORGOT_CHECK_KEY');

export const resetPasswordAsync = createAsyncAction('FORGOT_SUBMIT');

export const getAccountDetailAsync = createAsyncAction('AUTH_GET_DETAIL');

export const editAccountDetailAsync = createAsyncAction('AUTH_UPDATE_ACCOUNT');

export const editProfileDetailAsync = createAsyncAction('AUTH_UPDATE_PROFILE');

export const editBankDetailAsync = createAsyncAction('AUTH_UPDATE_BANK');
