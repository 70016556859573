import { createEpic, createUploadEpic } from '../helpers';

import {
  getVersionsAsync,
  createVersionsAsync,
  getLatestVersionAsync,
  getVersionDetailAsync,
  uploadApkAsync,
  uploadPcAsync,
} from './versionsActions';

export const getVersionsEpic = createEpic(
  getVersionsAsync,
  'versions',
  'getList',
  true
);
export const createVersionsEpic = createEpic(
  createVersionsAsync,
  'versions',
  'create',
  true
);
export const getLatestVersionEpic = createEpic(
  getLatestVersionAsync,
  'versions',
  'getLatest',
  true
);
export const uploadApkEpic = createUploadEpic(
  uploadApkAsync,
  'versions',
  'uploadApk',
  true
);
export const uploadPcEpic = createUploadEpic(
  uploadPcAsync,
  'versions',
  'uploadPc',
  true
);
export const getVersionDetailEpic = createEpic(
  getVersionDetailAsync,
  'versions',
  'getVersionDetail',
  true
);
