import { createDownloadEpic, createEpic } from '../helpers';
import {
  getStoreReportAsync,
  getReportAsync,
  exportStoreReportAsync,
  getReportStatusAsync,
  getStoreReportStatusAsync,
  exportReportAsync,
} from './reportsAction';

export const getStoreReportEpic = createEpic(
  getStoreReportAsync,
  'reports',
  'getStoreReport',
  true
);
export const getReportEpic = createEpic(
  getReportAsync,
  'reports',
  'getReport',
  true
);
export const getReportStatusEpic = createEpic(
  getReportStatusAsync,
  'reports',
  'getStatus',
  true
);
export const getStoreReportStatusEpic = createEpic(
  getStoreReportStatusAsync,
  'reports',
  'getStoreStatus',
  true
);
export const exportReportEpic = createDownloadEpic(
  exportReportAsync,
  'reports',
  'downloadReport',
  true,
  'report'
);
export const exportStoreReportEpic = createDownloadEpic(
  exportStoreReportAsync,
  'reports',
  'downloadStoreReport',
  true,
  'store-report'
);
