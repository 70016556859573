import { api, upload } from '../helpers';

export const submitDownloadDraft = ({ id, ...payload }, token) =>
  api(
    `/developer/game/${id}/download-draft/create`,
    'POST',
    payload,
    undefined,
    token
  );

export const getDownloadDraft = ({ id }, token) =>
  api(
    `/developer/game/${id}/download-draft/latest`,
    'GET',
    undefined,
    undefined,
    token
  );

export const uploadMediaDraft = (
  { id, downloadDraftId, media, progressEvent },
  token
) => {
  const formData = new FormData();
  media.forEach((v) => {
    formData.append('media_' + v.id, v.file, v.file.name);
  });
  return upload(
    `/developer/game/${id}/download-draft/${downloadDraftId}/media`,
    'PUT',
    formData,
    token,
    progressEvent
  );
};

export const uploadLogoDraft = (
  { id, downloadDraftId, logo, progressEvent },
  token
) => {
  const formData = new FormData();
  formData.append('logo', logo, logo.name);
  return upload(
    `/developer/game/${id}/download-draft/${downloadDraftId}/logo`,
    'PUT',
    formData,
    token,
    progressEvent
  );
};
