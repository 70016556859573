import { handleActions } from 'redux-actions';
import {
  getStoreReportAsync,
  getReportAsync,
  getReportStatusAsync,
  getStoreReportStatusAsync,
} from './reportsAction';

const initialState = {
  report: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  status: {
    purchases: -1,
    dayPurchase: -1,
    monthPurchase: -1,
  },
  action: {
    loading: false,
    success: false,
    uploaded: false,
    error: '',
  },
};
export const reportsReducer = handleActions(
  new Map([
    // Get store report
    [
      getStoreReportAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getStoreReportAsync.success,
      (state, { payload }) => ({
        ...state,
        report: payload,
        action: {
          ...initialState.action,
          loading: false,
        },
      }),
    ],
    [
      getStoreReportAsync.failure,
      (state, { payload }) => ({
        ...state,
        report: initialState.report,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],

    // Get report
    [
      getReportAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getReportAsync.success,
      (state, { payload }) => ({
        ...state,
        report: payload,
        action: {
          ...initialState.action,
          loading: false,
        },
      }),
    ],
    [
      getReportAsync.failure,
      (state, { payload }) => ({
        ...state,
        report: initialState.report,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],

    // Get store status
    [
      getStoreReportStatusAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getStoreReportStatusAsync.success,
      (state, { payload }) => ({
        ...state,
        status: payload,
        action: {
          ...initialState.action,
          loading: false,
        },
      }),
    ],
    [
      getStoreReportStatusAsync.failure,
      (state, { payload }) => ({
        ...state,
        status: initialState.status,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],

    // Get status
    [
      getReportStatusAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getReportStatusAsync.success,
      (state, { payload }) => ({
        ...state,
        status: payload,
        action: {
          ...initialState.action,
          loading: false,
        },
      }),
    ],
    [
      getReportStatusAsync.failure,
      (state, { payload }) => ({
        ...state,
        status: initialState.status,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
