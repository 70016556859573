import { api, download, upload } from '../helpers';

export const getReport = (param, token) =>
  api(`/developer/game/report`, 'GET', undefined, param, token);
export const getStoreReport = ({ id, ...param }, token) =>
  api(`/developer/game/${id}/report`, 'GET', undefined, param, token);
export const downloadStoreReport = ({ id }, token) =>
  download(`/developer/game/${id}/report/export`, undefined, token);
export const downloadReport = (param, token) =>
  download(`/developer/game/report/export`, undefined, token);
export const getStoreStatus = ({ id, ...param }, token) =>
  api(`/developer/game/${id}/report/status`, 'GET', undefined, param, token);
export const getStatus = (param, token) =>
  api(`/developer/game/report/status`, 'GET', undefined, param, token);
export const getLatest = ({ id }, token) =>
  api(`/developer/game/${id}/store/latest`, 'GET', undefined, undefined, token);
export const getCategories = () =>
  api(`/store-games/categories`, 'GET', undefined, undefined, undefined);
export const submit = ({ id, ...payload }, token) =>
  api(`/developer/game/${id}/store/create`, 'POST', payload, undefined, token);
export const uploadCsv = ({ id, storeId, csvFile, progressEvent }, token) => {
  const formData = new FormData();
  // console.log('csvFile', csvFile);
  formData.append('csv', csvFile, csvFile.name);
  return upload(
    `/developer/game/${id}/store/${storeId}/csv`,
    'PUT',
    formData,
    token,
    progressEvent
  );
};
export const uploadCover = (
  { id, storeId, coverFile, progressEvent },
  token
) => {
  const formData = new FormData();
  formData.append('cover', coverFile, coverFile.name);
  return upload(
    `/developer/game/${id}/store/${storeId}/cover`,
    'PUT',
    formData,
    token,
    progressEvent
  );
};
export const submitPublishStore = ({ id, storeId }, token) =>
  api(
    `/developer/game/${id}/store/submission/${storeId}/publish`,
    'PUT',
    undefined,
    undefined,
    token
  );
