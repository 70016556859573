import { handleActions, combineActions } from 'redux-actions';
import {
  getVersionsAsync,
  createVersionsAsync,
  getLatestVersionAsync,
  uploadApkAsync,
  uploadPcAsync,
  getVersionDetailAsync,
} from './versionsActions';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  detail: {},
  apkAction: {
    loading: false,
    success: false,
    error: '',
  },
  pcAction: {
    loading: false,
    success: false,
    error: '',
  },
  action: {
    loading: false,
    success: false,
    submitError: '',
    error: '',
  },
};

export const versionsReducer = handleActions(
  new Map([
    // Get Versions
    [
      getVersionsAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getVersionsAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
        action: {
          ...initialState.action,
          loading: false,
        },
      }),
    ],
    [
      getVersionsAsync.failure,
      (state, { payload }) => ({
        ...state,
        list: initialState.list,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],

    // Create version
    [
      combineActions(createVersionsAsync.request),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(createVersionsAsync.success),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      combineActions(createVersionsAsync.failure),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          submitError: payload,
        },
      }),
    ],

    // Get Version latest or detail
    [
      combineActions(
        getVersionDetailAsync.request,
        getLatestVersionAsync.request
      ),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(
        getVersionDetailAsync.success,
        getLatestVersionAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        detail: payload,
        action: {
          ...initialState.action,
          loading: false,
        },
      }),
    ],
    [
      combineActions(
        getVersionDetailAsync.failure,
        getLatestVersionAsync.failure
      ),
      (state, { payload }) => ({
        ...state,
        detail: initialState.detail,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],

    // Upload APK
    [
      uploadApkAsync.request,
      (state) => ({
        ...state,
        apkAction: {
          ...initialState.apkAction,
          loading: true,
        },
      }),
    ],
    [
      uploadApkAsync.success,
      (state, { payload }) => ({
        ...state,
        apkAction: {
          ...initialState.apkAction,
          success: payload,
          loading: false,
        },
      }),
    ],
    [
      uploadApkAsync.failure,
      (state, { payload }) => ({
        ...state,
        apkAction: {
          ...initialState.apkAction,
          loading: false,
          error: payload,
        },
      }),
    ],

    // Upload PC
    [
      uploadPcAsync.request,
      (state) => ({
        ...state,
        pcAction: {
          ...initialState.pcAction,
          loading: true,
        },
      }),
    ],
    [
      uploadPcAsync.success,
      (state, { payload }) => ({
        ...state,
        pcAction: {
          ...initialState.pcAction,
          success: payload,
          loading: false,
        },
      }),
    ],
    [
      uploadPcAsync.failure,
      (state, { payload }) => ({
        ...state,
        pcAction: {
          ...initialState.pcAction,
          loading: false,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
