import { handleActions } from 'redux-actions';
import { getReviewsAsync, submitReviewReplyAsync } from './reviewsAction';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  action: {
    loading: false,
    success: false,
    submitError: '',
    error: '',
  },
};

export const reviewReducer = handleActions(
  new Map([
    [
      getReviewsAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getReviewsAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
        action: {
          ...initialState.action,
          loading: false,
        },
      }),
    ],
    [
      getReviewsAsync.failure,
      (state, { payload }) => ({
        ...state,
        list: initialState.list,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],

    [
      submitReviewReplyAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      submitReviewReplyAsync.success,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      submitReviewReplyAsync.failure,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          submitError: payload,
        },
      }),
    ],
  ]),
  initialState
);
