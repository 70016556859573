import { api } from '../helpers';

export const doLogin = (payload) =>
  api('/auth/login', 'POST', { ...payload, type: 'DEVELOPER' });
export const getDetail = (param, token) =>
  api('/developer/me', 'GET', undefined, undefined, token);
export const checkActivationKey = (payload) =>
  api('/developer/activate/check', 'POST', payload);
export const submitActivationKey = (payload) =>
  api('/developer/activate', 'POST', payload);
export const requestForgotKey = (payload) =>
  api('/developer/forgot/request', 'POST', payload);
export const checkForgotKey = (payload) =>
  api('/developer/forgot/check', 'POST', payload);
export const resetPassword = (payload) =>
  api('/developer/forgot', 'POST', payload);
export const editAccount = (payload, token) =>
  api('/developer/me', 'PUT', payload, undefined, token);
export const editProfile = (payload, token) =>
  api('/developer/me/profile', 'PUT', payload, undefined, token);
export const editBank = (payload, token) =>
  api('/developer/me/bank', 'PUT', payload, undefined, token);
