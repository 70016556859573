import { createEpic } from '../helpers';
import { getReviewsAsync, submitReviewReplyAsync } from './reviewsAction';

export const getReviewsEpic = createEpic(
  getReviewsAsync,
  'reviews',
  'getReviews',
  true
);
export const submitReviewReplyEpic = createEpic(
  submitReviewReplyAsync,
  'reviews',
  'submitReviewReply',
  true
);
