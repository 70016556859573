import { createAsyncAction } from '../helpers';

export const submitItemSubmissionAsync = createAsyncAction(
  'SUBMIT_ITEM_SUBMISSION'
);
export const uploadCsvItemSubmissionAsync = createAsyncAction(
  'UPLOAD_CSV_ITEM_SUBMISSION'
);
export const getItemSubmissionNotRejectedAsync = createAsyncAction(
  'GET_ITEM_SUBMISSION_NOT_REJECTED'
);
export const getLatestItemSubmissionAllStatusAsync = createAsyncAction(
  'GET_LATEST_ITEM_SUBMISSION_ALL_STATUS'
);
