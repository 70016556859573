import {
  authLoginAsyncAction,
  checkActivationKeyAsync,
  submitActivationKeyAsync,
  requestForgotKeyAsync,
  checkPasswordKeyAsync,
  resetPasswordAsync,
  getAccountDetailAsync,
  editAccountDetailAsync,
  editProfileDetailAsync,
  editBankDetailAsync,
} from './authActions';
import { createEpic } from '../helpers';

export const authLoginEpic = createEpic(
  authLoginAsyncAction,
  'auth',
  'doLogin'
);

export const checkActivationKeyEpic = createEpic(
  checkActivationKeyAsync,
  'auth',
  'checkActivationKey'
);

export const submitActivationKeyEpic = createEpic(
  submitActivationKeyAsync,
  'auth',
  'submitActivationKey'
);

export const requestForgotKeyEpic = createEpic(
  requestForgotKeyAsync,
  'auth',
  'requestForgotKey'
);
export const checkPasswordKeyEpic = createEpic(
  checkPasswordKeyAsync,
  'auth',
  'checkForgotKey'
);
export const resetPasswordEpic = createEpic(
  resetPasswordAsync,
  'auth',
  'resetPassword'
);
export const getAccountDetailEpic = createEpic(
  getAccountDetailAsync,
  'auth',
  'getDetail',
  true
);
export const editAccountDetailEpic = createEpic(
  editAccountDetailAsync,
  'auth',
  'editAccount',
  true
);
export const editProfileDetailEpic = createEpic(
  editProfileDetailAsync,
  'auth',
  'editProfile',
  true
);
export const editBankDetailEpic = createEpic(
  editBankDetailAsync,
  'auth',
  'editBank',
  true
);
