import { createEpic, createUploadEpic } from '../helpers';
import {
  getStoreCategoriesAsync,
  submitStoreAsync,
  uploadCoverAsync,
  uploadCsvAsync,
  getLatestStoreSubmissionAsync,
  submitPublishStoreAsync,
} from './storesAction';

export const getStoreCategoriesEpic = createEpic(
  getStoreCategoriesAsync,
  'stores',
  'getCategories',
  true
);
export const submitStoreEpic = createEpic(
  submitStoreAsync,
  'stores',
  'submit',
  true
);
export const uploadCoverEpic = createUploadEpic(
  uploadCoverAsync,
  'stores',
  'uploadCover',
  true
);
export const uploadCsvEpic = createUploadEpic(
  uploadCsvAsync,
  'stores',
  'uploadCsv',
  true
);
export const getLatestStoreSubmissionEpic = createEpic(
  getLatestStoreSubmissionAsync,
  'stores',
  'getLatest',
  true
);
export const submitPublishStoreEpic = createEpic(
  submitPublishStoreAsync,
  'stores',
  'submitPublishStore',
  true
);
