import { combineActions, handleActions } from 'redux-actions';
import {
  getStoreCategoriesAsync,
  submitStoreAsync,
  uploadCoverAsync,
  uploadCsvAsync,
  getLatestStoreSubmissionAsync,
  submitPublishStoreAsync,
} from './storesAction';

const initialState = {
  report: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  storeReport: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  latestStore: {},
  storeStatus: {},
  status: {},
  categories: [],
  submitAction: {
    coverSuccess: false,
    coverLoading: false,
    coverError: '',
    csvSuccess: false,
    csvLoading: false,
    csvError: '',
  },
  fetch: {
    loading: false,
    error: '',
  },
  action: {
    loading: false,
    success: false,
    changed: null,
    uploaded: false,
    error: '',
    submitError: '',
  },
};
export const storeReducer = handleActions(
  new Map([
    // Get latest Store
    [
      getLatestStoreSubmissionAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getLatestStoreSubmissionAsync.success,
      (state, { payload }) => ({
        ...state,
        latestStore: payload,
        action: {
          ...initialState.action,
          loading: false,
        },
      }),
    ],
    [
      getLatestStoreSubmissionAsync.failure,
      (state, { payload }) => ({
        ...state,
        latestStore: initialState.latestStore,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],

    // Get categories
    [
      getStoreCategoriesAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getStoreCategoriesAsync.success,
      (state, { payload }) => ({
        ...state,
        categories: payload,
        action: {
          ...initialState.action,
          loading: false,
        },
      }),
    ],
    [
      getStoreCategoriesAsync.failure,
      (state, { payload }) => ({
        ...state,
        categories: initialState.categories,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],

    // submit store
    [
      combineActions(submitStoreAsync.request, submitPublishStoreAsync.request),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(submitStoreAsync.success, submitPublishStoreAsync.success),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
          loading: false,
        },
      }),
    ],
    [
      combineActions(submitStoreAsync.failure, submitPublishStoreAsync.failure),
      (state, { payload }) => ({
        ...state,
        detail: initialState.detail,
        action: {
          ...initialState.action,
          loading: false,
          submitError: payload,
        },
      }),
    ],

    // Upload csv
    [
      uploadCsvAsync.request,
      (state) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          csvSuccess: false,
          csvLoading: true,
          csvError: '',
        },
      }),
    ],
    [
      uploadCsvAsync.success,
      (state, { payload }) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          csvSuccess: payload,
          csvLoading: false,
          csvError: false,
        },
      }),
    ],
    [
      uploadCsvAsync.failure,
      (state, { payload }) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          csvSuccess: false,
          csvLoading: false,
          csvError: payload,
        },
        action: {
          ...state.action,
          success: false,
        },
      }),
    ],

    // Upload cover
    [
      uploadCoverAsync.request,
      (state) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          coverSuccess: false,
          coverLoading: true,
          coverError: '',
        },
      }),
    ],
    [
      uploadCoverAsync.success,
      (state, { payload }) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          coverSuccess: payload,
          coverLoading: false,
          coverError: '',
        },
      }),
    ],
    [
      uploadCoverAsync.failure,
      (state, { payload }) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          coverSuccess: false,
          coverLoading: false,
          coverError: payload,
        },
        action: {
          ...state.action,
          success: false,
        },
      }),
    ],
  ]),
  initialState
);
