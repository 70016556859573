import { api } from '../helpers';

export const getLatestHistory = ({ id, ...params }, token) =>
  api(`/developer/game/${id}/history`, 'GET', undefined, params, token);

export const getHistoryDetail = ({ id, downloadId, ...params }, token) =>
  api(
    `/developer/game/${id}/history/${downloadId}`,
    'GET',
    undefined,
    params,
    token
  );
