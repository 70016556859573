import { api, upload } from '../helpers';

export const getVersionList = ({ id }, token) =>
  api(
    `/developer/game/${id}/versions/list`,
    'GET',
    undefined,
    undefined,
    token
  );
export const getLastDownload = ({ id }, token) =>
  api(
    `/developer/game/${id}/download/latest`,
    'GET',
    undefined,
    undefined,
    token
  );
export const getDownloadCategories = (params, token) =>
  api('/download/categories', 'GET', undefined, params, token);
export const submit = ({ id, ...payload }, token) =>
  api(
    `/developer/game/${id}/download/create`,
    'POST',
    payload,
    undefined,
    token
  );
export const uploadMedia = (
  { id, downloadId, media, progressEvent },
  token
) => {
  const formData = new FormData();
  media.forEach((v) => {
    formData.append('media_' + v.id, v.file, v.file.name);
  });
  return upload(
    `/developer/game/${id}/download/${downloadId}/media`,
    'PUT',
    formData,
    token,
    progressEvent
  );
};
export const uploadLogo = ({ id, downloadId, logo, progressEvent }, token) => {
  const formData = new FormData();
  formData.append('logo', logo, logo.name);
  return upload(
    `/developer/game/${id}/download/${downloadId}/logo`,
    'PUT',
    formData,
    token,
    progressEvent
  );
};
export const submitPublish = ({ id, downloadId }, token) =>
  api(
    `/developer/game/${id}/download/submission/${downloadId}/publish`,
    'PUT',
    undefined,
    undefined,
    token
  );
