import { api, upload } from '../helpers';

export const getList = ({ id, ...params }, token) =>
  api(`/developer/game/${id}/versions`, 'GET', undefined, params, token);
export const create = ({ id, ...payload }, token) =>
  api(
    `/developer/game/${id}/versions/create`,
    'POST',
    payload,
    undefined,
    token
  );
export const getLatest = ({ id }, token) =>
  api(
    `/developer/game/${id}/versions/latest`,
    'GET',
    undefined,
    undefined,
    token
  );
export const getVersionDetail = ({ id, versionId }, token) =>
  api(
    `/developer/game/${id}/versions/${versionId}`,
    'GET',
    undefined,
    undefined,
    token
  );
export const uploadApk = ({ id, versionId, apk, progressEvent }, token) => {
  const formData = new FormData();
  formData.append('apk', apk, apk.name);
  return upload(
    `/developer/game/${id}/versions/${versionId}/apk`,
    'PUT',
    formData,
    token,
    progressEvent
  );
};
export const uploadPc = ({ id, versionId, pcFile, progressEvent }, token) => {
  const formData = new FormData();
  formData.append('pc', pcFile, pcFile.name);
  return upload(
    `/developer/game/${id}/versions/${versionId}/pc`,
    'PUT',
    formData,
    token,
    progressEvent
  );
};
