import { api } from '../helpers';

export const getList = ({ id, ...params }, token) =>
  api(`/developer/game/${id}/items/list`, 'GET', undefined, params, token);
export const getItems = ({ id, ...params }, token) =>
  api(`/developer/game/${id}/items`, 'GET', undefined, undefined, token);
export const getDetail = ({ id, itemId, ...params }, token) =>
  api(`/developer/game/${id}/items/${itemId}`, 'GET', undefined, params, token);
export const deleteItem = ({ id, itemId }, token) =>
  api(
    `/developer/game/${id}/items/${itemId}`,
    'DELETE',
    undefined,
    undefined,
    token
  );
export const submitItem = ({ id, ...payload }, token) =>
  api(`/developer/game/${id}/items/create`, 'POST', payload, undefined, token);
export const updateItem = ({ id, itemId, ...payload }, token) =>
  api(
    `/developer/game/${id}/items/${itemId}`,
    'PUT',
    payload,
    undefined,
    token
  );
