import { api, upload } from '../helpers';

export const submitItemSubmission = ({ id, ...payload }, token) =>
  api(
    `/developer/game/${id}/item-submission`,
    'POST',
    payload,
    undefined,
    token
  );
export const uploadCsvItemSubmission = (
  { id, itemSubmissionId, csvFile, progressEvent },
  token
) => {
  const formData = new FormData();
  // console.log('csvFile', csvFile);
  formData.append('csv', csvFile, csvFile.name);
  return upload(
    `/developer/game/${id}/item-submission/${itemSubmissionId}/csv`,
    'PUT',
    formData,
    token,
    progressEvent
  );
};
export const getItemSubmissionNotRejected = ({ id }, token) =>
  api(
    `/developer/game/${id}/item-submission/not-rejected`,
    'GET',
    undefined,
    undefined,
    token
  );
export const getLatestItemSubmissionAllStatus = ({ id }, token) =>
  api(
    `/developer/game/${id}/item-submission/all-status`,
    'GET',
    undefined,
    undefined,
    token
  );
