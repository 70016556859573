import { combineActions, handleActions } from 'redux-actions';

import {
  getDownloadDraftAsync,
  submitDownloadDraftAsync,
  uploadLogoDraftAsync,
  uploadMediaDraftAsync,
} from './downloadDraftAction';

const initialState = {
  downloadDraftDetailDraft: {},
  fetch: {
    versionDraftLoading: false,
    categoriesDraftLoading: false,
    downloadDraftDetailDraftLoading: false,
  },
  mediaDraftAction: {
    loading: false,
    success: false,
    error: '',
  },
  logoDraftAction: {
    loading: false,
    success: false,
    error: '',
  },
  actionDraft: {
    loading: false,
    success: false,
    submitError: '',
    error: '',
  },
};

export const downloadDraftReducer = handleActions(
  new Map([
    // Get Draft
    [
      getDownloadDraftAsync.request,
      (state) => ({
        ...state,
        fetch: {
          ...state.fetch,
          downloadDraftDetailLoading: true,
        },
      }),
    ],
    [
      getDownloadDraftAsync.success,
      (state, { payload }) => ({
        ...state,
        downloadDraftDetail: payload,
        fetch: {
          ...state.fetch,
          downloadDraftDetailLoading: false,
        },
      }),
    ],
    [
      getDownloadDraftAsync.failure,
      (state, { payload }) => ({
        ...state,
        downloadDraftDetail: initialState.downloadDraftDetail,
        fetch: initialState.fetch,
        actionDraft: {
          ...initialState.actionDraft,
          error: payload,
        },
      }),
    ],

    // Submit Draft
    [
      combineActions(submitDownloadDraftAsync.request),
      (state) => ({
        ...state,
        actionDraft: {
          ...initialState.actionDraft,
          loading: true,
        },
      }),
    ],
    [
      combineActions(submitDownloadDraftAsync.success),
      (state, { payload }) => ({
        ...state,
        actionDraft: {
          ...initialState.actionDraft,
          success: payload,
        },
      }),
    ],
    [
      combineActions(submitDownloadDraftAsync.failure),
      (state, { payload }) => ({
        ...state,
        actionDraft: {
          ...initialState.actionDraft,
          submitError: payload,
        },
      }),
    ],

    // Submit Media
    [
      combineActions(uploadMediaDraftAsync.request),
      (state) => ({
        ...state,
        mediaDraftAction: {
          ...initialState.mediaDraftAction,
          loading: true,
        },
      }),
    ],
    [
      combineActions(uploadMediaDraftAsync.success),
      (state, { payload }) => ({
        ...state,
        mediaDraftAction: {
          ...initialState.mediaDraftAction,
          success: payload,
        },
      }),
    ],
    [
      combineActions(uploadMediaDraftAsync.failure),
      (state, { payload }) => ({
        ...state,
        mediaDraftAction: {
          ...initialState.mediaDraftAction,
          submitError: payload,
        },
      }),
    ],

    // Submit Logo
    [
      combineActions(uploadLogoDraftAsync.request),
      (state) => ({
        ...state,
        logoDraftAction: {
          ...initialState.logoDraftAction,
          loading: true,
        },
      }),
    ],
    [
      combineActions(uploadLogoDraftAsync.success),
      (state, { payload }) => ({
        ...state,
        logoDraftAction: {
          ...initialState.logoDraftAction,
          success: payload,
        },
      }),
    ],
    [
      combineActions(uploadLogoDraftAsync.failure),
      (state, { payload }) => ({
        ...state,
        logoDraftAction: {
          ...initialState.logoDraftAction,
          submitError: payload,
        },
      }),
    ],
  ]),
  initialState
);
