import { handleActions, combineActions } from 'redux-actions';
import {
  getGamesAsync,
  createGameAsync,
  getGameDetailAsync,
  updateGameAsync,
  uploadGameThumbnailAsync,
  getAccessUserListAsync,
  getAccessUserDetailAsync,
  updateAccessUserAsync,
  deleteAccessUserAsync,
  submitUserAccessAsync,
} from './gamesActions';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  detail: {},
  action: {
    loading: false,
    success: false,
    error: '',
  },
  thumbnail: {
    loading: false,
    success: false,
    error: '',
  },
  isAdmin: {
    loading: false,
    success: false,
    error: '',
  },
  accessUser: {
    loading: false,
    list: {
      items: [],
      meta: {
        totalPages: 0,
      },
    },
    success: false,
    detail: {},
    error: '',
  },
};

export const gamesReducer = handleActions(
  new Map([
    // Get Games
    [
      getGamesAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getGamesAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
        action: {
          ...initialState.action,
          loading: false,
        },
      }),
    ],
    [
      getGamesAsync.failure,
      (state, { payload }) => ({
        ...state,
        list: initialState.list,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],

    // Get Game Detail
    [
      getGameDetailAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getGameDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
        action: {
          ...initialState.action,
          loading: false,
        },
      }),
    ],
    [
      getGameDetailAsync.failure,
      (state, { payload }) => ({
        ...state,
        detail: initialState.detail,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],

    // Upload Thumbnail
    [
      uploadGameThumbnailAsync.request,
      (state) => ({
        ...state,
        thumbnail: {
          ...initialState.thumbnail,
          loading: true,
        },
      }),
    ],
    [
      uploadGameThumbnailAsync.success,
      (state, { payload }) => ({
        ...state,
        thumbnail: {
          ...initialState.thumbnail,
          success: payload,
        },
      }),
    ],
    [
      uploadGameThumbnailAsync.failure,
      (state, { payload }) => ({
        ...state,
        thumbnail: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],

    // Create Game
    [
      combineActions(createGameAsync.request, updateGameAsync.request),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(createGameAsync.success, updateGameAsync.success),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      combineActions(createGameAsync.failure, updateGameAsync.failure),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],

    // Get access user list
    [
      getAccessUserListAsync.request,
      (state) => ({
        ...state,
        accessUser: {
          ...initialState.accessUser,
          loading: true,
        },
      }),
    ],
    [
      getAccessUserListAsync.success,
      (state, { payload }) => ({
        ...state,
        accessUser: {
          ...initialState.accessUser,
          list: payload,
        },
      }),
    ],
    [
      getAccessUserListAsync.failure,
      (state, { payload }) => ({
        ...state,
        accessUser: {
          ...initialState.accessUser,
          error: payload,
        },
      }),
    ],

    // Get access user detail
    [
      getAccessUserDetailAsync.request,
      (state) => ({
        ...state,
        accessUser: {
          ...initialState.accessUser,
          loading: true,
        },
      }),
    ],
    [
      getAccessUserDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        accessUser: {
          ...initialState.accessUser,
          detail: payload,
        },
      }),
    ],
    [
      getAccessUserDetailAsync.failure,
      (state, { payload }) => ({
        ...state,
        accessUser: {
          ...initialState.accessUser,
          error: payload,
        },
      }),
    ],

    // Create User Access
    [
      submitUserAccessAsync.request,
      (state) => ({
        ...state,
        accessUser: {
          ...state.accessUser,
          loading: true,
          success: false,
        },
      }),
    ],
    [
      submitUserAccessAsync.success,
      (state, { payload }) => ({
        ...state,
        accessUser: {
          ...state.accessUser,
          success: payload,
          loading: false,
        },
      }),
    ],
    [
      submitUserAccessAsync.failure,
      (state, { payload }) => ({
        ...state,
        accessUser: {
          ...state.accessUser,
          error: payload,
          loading: false,
        },
      }),
    ],

    // Update/delete game access
    [
      combineActions(
        updateAccessUserAsync.request,
        deleteAccessUserAsync.request
      ),
      (state) => ({
        ...state,
        accessUser: {
          ...initialState.accessUser,
          loading: true,
        },
      }),
    ],
    [
      combineActions(
        updateAccessUserAsync.success,
        deleteAccessUserAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        accessUser: {
          ...initialState.accessUser,
          success: payload,
        },
      }),
    ],
    [
      combineActions(
        updateAccessUserAsync.failure,
        deleteAccessUserAsync.failure
      ),
      (state, { payload }) => ({
        ...state,
        accessUser: {
          ...initialState.accessUser,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
