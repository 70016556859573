import { api } from '../helpers';
export const getGameDetail = ({ id }, token) =>
  api(`/developer/game/${id}/detail`, 'GET', undefined, undefined, token);
export const getGameStatus = ({ id }, token) =>
  api(`/developer/game/${id}/status`, 'GET', undefined, undefined, token);
export const getGameDetailSandbox = ({ id }, token) =>
  api(
    `/developer/game/${id}/detail-sandbox`,
    'GET',
    undefined,
    undefined,
    token
  );
