import * as auth from './auth/auth.service';
import * as games from './games/games.service';
import * as dashboard from './dashboard/dashboard.service';
import * as stores from './stores/stores.service';
import * as download from './download/download.service';
import * as downloadDraft from './downloadDraft/downloadDraft.service';
import * as versions from './versions/versions.service';
import * as item from './item/item.service';
import * as reports from './reports/reports.service';
import * as storesDraft from './storesDraft/storesDraft.service';
import * as downloadHistory from './downloadHistory/downloadHistory.service';
import * as itemSubmission from './itemSubmission/item-submission.service';
import * as reviews from './reviews/reviews.service';

export default {
  auth,
  games,
  dashboard,
  stores,
  storesDraft,
  download,
  downloadDraft,
  versions,
  item,
  reports,
  downloadHistory,
  itemSubmission,
  reviews,
};
