import { handleActions } from 'redux-actions';
import {
  getItemSubmissionNotRejectedAsync,
  getLatestItemSubmissionAllStatusAsync,
  submitItemSubmissionAsync,
  uploadCsvItemSubmissionAsync,
} from './itemSubmissionAction';

const initialState = {
  latestSubmissionAllStatus: {},
  latestSubmissionNotRejected: {},
  action: {
    loading: false,
    success: false,
    error: '',
    submitError: '',
  },
  submitAction: {
    csvSuccess: false,
    csvLoading: false,
    csvError: '',
  },
};

export const itemSubmissionReducer = handleActions(
  new Map([
    [
      submitItemSubmissionAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      submitItemSubmissionAsync.success,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
          loading: false,
        },
      }),
    ],
    [
      submitItemSubmissionAsync.failure,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          submitError: payload,
        },
      }),
    ],

    // Upload csv
    [
      uploadCsvItemSubmissionAsync.request,
      (state) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          csvSuccess: false,
          csvLoading: true,
          csvError: '',
        },
      }),
    ],
    [
      uploadCsvItemSubmissionAsync.success,
      (state, { payload }) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          csvSuccess: payload,
          csvLoading: false,
          csvError: false,
        },
      }),
    ],
    [
      uploadCsvItemSubmissionAsync.failure,
      (state, { payload }) => ({
        ...state,
        submitAction: {
          ...state.submitAction,
          csvSuccess: false,
          csvLoading: false,
          csvError: payload,
        },
        action: {
          ...state.action,
          success: false,
        },
      }),
    ],

    // Get item submission not rejected
    [
      getItemSubmissionNotRejectedAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getItemSubmissionNotRejectedAsync.success,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: false,
        },
        latestSubmissionNotRejected: payload,
      }),
    ],
    [
      getItemSubmissionNotRejectedAsync.failure,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],

    // Get Latest Item Submission
    [
      getLatestItemSubmissionAllStatusAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getLatestItemSubmissionAllStatusAsync.success,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: false,
        },
        latestSubmissionAllStatus: payload,
      }),
    ],
    [
      getLatestItemSubmissionAllStatusAsync.failure,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
