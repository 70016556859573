import { createEpic } from '../helpers';
import {
  getHistoryDetailAsync,
  getLatestHistoryAsync,
} from './downloadHistoryAction';

export const getLatestHistoryEpic = createEpic(
  getLatestHistoryAsync,
  'downloadHistory',
  'getLatestHistory',
  true
);

export const getHistoryDetailEpic = createEpic(
  getHistoryDetailAsync,
  'downloadHistory',
  'getHistoryDetail',
  true
);
