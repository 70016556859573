import { createAsyncAction } from '../helpers';

export const getStoreCategoriesAsync = createAsyncAction(
  'GET_STORE_CATEGORIES'
);
export const getLatestStoreSubmissionAsync =
  createAsyncAction('GET_LATEST_STORE');
export const submitStoreAsync = createAsyncAction('SUBMIT_STORE');
export const uploadCsvAsync = createAsyncAction('UPLOAD_CSV');
export const uploadCoverAsync = createAsyncAction('UPLOAD_COVER');
export const submitPublishStoreAsync = createAsyncAction(
  'SUBMIT_PUBLISH_STORE'
);
