import { createEpic, createUploadEpic } from '../helpers';

import {
  getGamesAsync,
  createGameAsync,
  uploadGameThumbnailAsync,
  getGameDetailAsync,
  updateGameAsync,
  getAccessUserListAsync,
  getAccessUserDetailAsync,
  updateAccessUserAsync,
  deleteAccessUserAsync,
  submitUserAccessAsync,
} from './gamesActions';

export const getGamesEpic = createEpic(getGamesAsync, 'games', 'getList', true);
export const createGameEpic = createEpic(
  createGameAsync,
  'games',
  'create',
  true
);
export const updateGameEpic = createEpic(
  updateGameAsync,
  'games',
  'update',
  true
);
export const getGameDetailEpic = createEpic(
  getGameDetailAsync,
  'games',
  'getDetail',
  true
);
export const uploadGameThumbnailEpic = createUploadEpic(
  uploadGameThumbnailAsync,
  'games',
  'uploadThumbnail',
  true
);
export const getAccessUserListEpic = createEpic(
  getAccessUserListAsync,
  'games',
  'getAccessUserList',
  true
);
export const getAccessUserDetailEpic = createEpic(
  getAccessUserDetailAsync,
  'games',
  'getAccessUserDetail',
  true
);
export const updateAccessUserEpic = createEpic(
  updateAccessUserAsync,
  'games',
  'updateAccessUser',
  true
);
export const deleteAccessUserEpic = createEpic(
  deleteAccessUserAsync,
  'games',
  'deleteAccessUser',
  true
);
export const submitUserAccessEpic = createEpic(
  submitUserAccessAsync,
  'games',
  'submitUserAccess',
  true
);
