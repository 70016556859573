import { handleActions } from 'redux-actions';
import {
  getHistoryDetailAsync,
  getLatestHistoryAsync,
} from './downloadHistoryAction';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  detailList: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  action: {
    loading: false,
    success: false,
    error: '',
  },
};

export const downloadHistoryReducer = handleActions(
  new Map([
    // Get Latest History
    [
      getLatestHistoryAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getLatestHistoryAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
        action: {
          ...initialState.action,
          loading: false,
        },
      }),
    ],
    [
      getLatestHistoryAsync.failure,
      (state, { payload }) => ({
        ...state,
        list: initialState.list,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],

    // Get History Detail
    [
      getHistoryDetailAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      getHistoryDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        detailList: payload,
        action: {
          ...initialState.action,
          loading: false,
        },
      }),
    ],
    [
      getHistoryDetailAsync.failure,
      (state, { payload }) => ({
        ...state,
        detailList: initialState.detailList,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
