import { createEpic, createUploadEpic } from '../helpers';
import {
  getAllVersionAsync,
  getLastDownloadAsync,
  getDownloadCategoriesAsync,
  submitDownloadAsync,
  uploadMediaAsync,
  uploadLogoAsync,
  submitPublishDownloadAsync,
} from './downloadAction';

export const getAllVersionEpic = createEpic(
  getAllVersionAsync,
  'download',
  'getVersionList',
  true
);
export const getLastDownloadEpic = createEpic(
  getLastDownloadAsync,
  'download',
  'getLastDownload',
  true
);
export const getDownloadCategoriesEpic = createEpic(
  getDownloadCategoriesAsync,
  'download',
  'getDownloadCategories',
  true
);
export const submitDownloadEpic = createEpic(
  submitDownloadAsync,
  'download',
  'submit',
  true
);
export const uploadMediaEpic = createUploadEpic(
  uploadMediaAsync,
  'download',
  'uploadMedia',
  true
);
export const uploadLogoEpic = createUploadEpic(
  uploadLogoAsync,
  'download',
  'uploadLogo',
  true
);
export const submitPublishDownloadEpic = createEpic(
  submitPublishDownloadAsync,
  'download',
  'submitPublish',
  true
);
