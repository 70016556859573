import { api, upload } from '../helpers';

export const getLatestStoreDraft = ({ id }, token) =>
  api(
    `/developer/game/${id}/store-draft/latest`,
    'GET',
    undefined,
    undefined,
    token
  );

export const submitStoreDraft = ({ id, ...payload }, token) =>
  api(
    `/developer/game/${id}/store-draft/create`,
    'POST',
    payload,
    undefined,
    token
  );

export const uploadCoverDraft = (
  { id, storeDraftId, coverFile, progressEvent },
  token
) => {
  const formData = new FormData();
  formData.append('cover', coverFile, coverFile.name);
  return upload(
    `/developer/game/${id}/store-draft/${storeDraftId}/cover`,
    'PUT',
    formData,
    token,
    progressEvent
  );
};

export const uploadCsvDraft = (
  { id, storeDraftId, csvFile, progressEvent },
  token
) => {
  const formData = new FormData();
  formData.append('csv', csvFile, csvFile.name);
  return upload(
    `/developer/game/${id}/store-draft/${storeDraftId}/csv`,
    'PUT',
    formData,
    token,
    progressEvent
  );
};

export const deleteStoreDraft = ({ id }, token) => {
  return api(
    `/developer/game/${id}/store-draft`,
    'DELETE',
    undefined,
    undefined,
    token
  );
};
